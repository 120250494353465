import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Footer from "./Footer"
import { PageMetadataProvider } from "../pageMetadata"
import ActiveModal from "./modals/ActiveModal"

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export default function PageLayout({ lang, children }) {
  const intl = useIntl()
  const pageMetadata = {
    translations: [],
  }

  return (
    <PageMetadataProvider value={pageMetadata}>
      <Wrapper>
        {children}
        <Footer
          lang={lang}
          title={intl.formatMessage({ id: "siteTitle" })}
          email={intl.formatMessage({ id: "emailAddress" })}
        />
      </Wrapper>
      <ActiveModal />
    </PageMetadataProvider>
  )
}
