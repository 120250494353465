import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const IconLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  font-family: ${props => props.theme.headingFont};
  text-decoration: none;
  color: #fff;
  @media only screen and (orientation: portrait) {
    flex-direction: column;
  }
  &:hover,
  &:focus {
    color: #fff;
  }
`
const Icon = styled.svg`
  width: 32px;
  height: 36px;
  margin-right: 0.75rem;
  @media only screen and (orientation : portrait) {
    margin-right: 0;
    margin-bottom: .5rem;
  }
}
`
const Label = styled.span``

const PersonsIcon = (
  <Icon
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 36"
  >
    <defs />
    <path
      fill="#fff"
      d="M14.5 13A8 8 0 015 13c-1.1.9-2.3 2.3-3.2 4-2.2 3.8-2.4 7.8-.5 8.8.8.5 1.7.2 2.6-.7-.2 1-.3 1.9-.2 2.8 0 4.5 1.7 8.1 3.8 8.1 1.3 0 2-1.3 2.3-3.3.3 2 1 3.3 2.2 3.3 2.2 0 4-3.6 4-8l-.3-3c.9 1 1.8 1.3 2.6.8 1.9-1 1.6-5-.5-8.9-1-1.6-2.1-3-3.3-4zm-4.7 0a6.6 6.6 0 006-4A6.4 6.4 0 0012.3.5 6.6 6.6 0 005 1.9 6.5 6.5 0 009.8 13z"
    />
    <path
      fill="#fff"
      fillOpacity=".5"
      d="M26.3 21.2c2.3 0 4.1-1.9 4.1-4.2 0-2.3-1.8-4.1-4.1-4.1a4.1 4.1 0 100 8.3zm5 2.5a9 9 0 00-2-2.5 5.1 5.1 0 01-6 0 9 9 0 00-2.1 2.5c-1.4 2.5-1.6 5-.4 5.7.5.3 1.1 0 1.7-.5l-.2 1.8c0 2.9 1.2 5.2 2.5 5.2.9 0 1.3-.8 1.5-2.1.2 1.2.6 2 1.4 2 1.4 0 2.5-2.2 2.5-5 0-.7 0-1.3-.2-1.9.6.6 1.2.8 1.7.5 1.2-.7 1-3.2-.3-5.7z"
    />
  </Icon>
)

const IconButton = ({ url }) => {
  const intl = useIntl()
  return (
    <IconLink to={url}>
      {PersonsIcon}
      <Label>
        {intl.formatMessage({
          id: "goToTextPages",
          defaultMessage: "För vuxna",
        })}
      </Label>
    </IconLink>
  )
}

export default IconButton
