import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Logo from "../components/LogoFull"
import PageLayout from "../components/PageLayout"
import IconLink from "../components/IconLink"
import LanguageDropdown from "../components/LanguageDropdown"
import ActionButton from "../components/ActionButton"
import StartPageContent from "../components/StartPageContent"
import SettingsIcon from "../components/icons/Settings"
import TryggwebbButton from "../components/TryggwebbButton"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import { useModal } from "../hooks/modal"
import stripTags from "../utils/strip-tags"

const AreaContainer = styled.div`
  height: 100%;
  background: linear-gradient(
    0deg,
    ${props => props.theme.red[3]} 0%,
    ${props => props.theme.red[2]} 100%
  );
`
const StyledLogo = styled(Logo)`
  position: absolute;
  z-index: 10;
  width: 13.25rem;
  height: auto;

  @media (orientation: portrait) {
    top: 1rem;
    left: 1rem;
  }

  @media (orientation: landscape) {
    top: 1.5rem;
    left: 1.5rem;

    @media (min-width: 1024px) and (min-height: 600px) {
      width: 20rem;
    }

    @media (min-width: 1900px) and (min-height: 1024px) {
      width: 30rem;
    }
  }
`
const StyledStartPageContent = styled(StartPageContent)`
  grid-area: content;
  @media (orientation: portrait) {
    align-self: end;
  }
`

const ForGrownUps = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
`

const LanguageAndSettings = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2rem);
`
const StyledLanguageDropdown = styled(LanguageDropdown)`
  margin-left: -0.5rem;
  z-index: 99;
`
const SettingsButton = styled(ActionButton)`
  margin-left: auto;
`

export default function StartPageTemplate({
  data: { allCiceronYaml, allTextsYaml },
  pageContext: { intl: { language }, forGrownUpsLink },
}) {
  const intl = useIntl()
  const { openModal } = useModal()

  return (
    <PageLayout lang={language}>
      <NwHelmet
        lang={language}
        path=""
        image={{
          url: `/assets/share-images/NW_Delning1_Std_${language === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
        desc={stripTags(get(allTextsYaml.nodes.find((x) => x.lang === language), "text.html"))}
      />
      <AreaContainer>
        <StyledLogo lang={language} />
        {forGrownUpsLink[language] &&
          <ForGrownUps>
            <IconLink url={forGrownUpsLink[language]} />
          </ForGrownUps>
        }
        <StyledStartPageContent lang={language} />
        <LanguageAndSettings>
          <StyledLanguageDropdown
            activeLanguage={language}
            up
            ui
          />
          <TryggwebbButton
            lang={language}
            room="start"
            data={allCiceronYaml.nodes.find((x) => x.lang === language)}
            position="center"
          />
          <SettingsButton onClick={(e) => { openModal(e, "settings", { lang: language }) }}>
            <SettingsIcon />
            {intl.formatMessage({ id: "showSettings" })}
          </SettingsButton>
        </LanguageAndSettings>
      </AreaContainer>
      <BrowserUpdateMessage />
      <CookieBanner lang={language} />
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query {
    allCiceronYaml(filter: { _id: { eq: "start" } }) {
      nodes {
        lang
        text
        sound
      }
    }
    allTextsYaml(filter: { _id: { eq: "descriptionStartPage" } }) {
      nodes {
        lang
        text {
          html
        }
      }
    }
  }
`
