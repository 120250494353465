import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import { useStore } from "../hooks/store"
import getRoomLink from "../utils/get-room-link"

import beamMask from "../masks/beam.svg"
import roomBlockShadow from "../images/room-block-shadow.svg"
import roomBlock1 from "../images/room-block-1.svg"
import roomBlock2 from "../images/room-block-2.svg"
import roomBlock3 from "../images/room-block-3.svg"
import roomBlock4 from "../images/room-block-4.svg"
import roomBlock5 from "../images/room-block-5.svg"
import roomBlock6 from "../images/room-block-6.svg"
import roomBlock7 from "../images/room-block-7.svg"
import roomBlock8 from "../images/room-block-8.svg"
import navBackdrop from "../images/primary-nav-backdrop.svg"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;

  @media (orientation: portrait) {
    font-size: 84%;

    @media (min-width: 375px) {
      font-size: 100%;
    }

    @media (min-width: 768px) and (min-height: 800px) {
      font-size: 130%;
    }

    @media (min-width: 1024px) and (min-height: 1000px) {
      font-size: 170%;
    }
  }

  @media (orientation: landscape) {
    font-size: 70%;

    @media (min-width: 600px) {
      font-size: 80%;
    }

    @media (min-width: 660px) and (min-height: 300px) {
      font-size: 100%;
    }

    @media (min-width: 1024px) and (min-height: 600px) {
      font-size: 110%;
    }

    @media (min-width: 1024px) and (min-height: 1000px) {
      font-size: 120%;
    }

    @media (min-width: 1900px) and (min-height: 1024px) {
      font-size: 150%;
    }
  }
`

const Beam = styled.div`
  mask-image: ${`url(${beamMask})`};
  mask-position: bottom;
  mask-size: 100% auto;
  mask-repeat: no-repeat;
  background-image: linear-gradient(0deg, #fff0 10%, #fff8 100%);
  background-size: 100%;
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 50vh;
  bottom: 60%;
  pointer-events: none;

  @media (orientation: portrait), (orientation: landscape) and (min-width: 600px) and (min-height: 600px) {
    height: 80vh;
  }
`

const Nav = styled.nav`
  position: absolute;
  width: 20.8125em;
  height: 14.5625em;

  @media (orientation: portrait) {
    bottom: 13.8%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media (orientation: landscape) {
    right: 12.7%;
    bottom: 19.5%;
  }

  .nav-block {
    width: 100%;
    height: auto;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      transform: translate3d(0, -4px, 0);
    }
  }

  .nav-block.bounce {
    animation-iteration-count: 1;
    animation-name: bounce;
    animation-duration: 1s;
    transform-origin: center bottom;
  }

  @keyframes shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }

  .nav-block.shake {
    animation-name: shake;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }

  @keyframes swing {
    20% {
      transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
      transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
      transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
      transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }

  .nav-block.swing {
    transform-origin: top center;
    animation-name: swing;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }

  @keyframes tada {
    from {
      transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .nav-block.tada {
    animation-name: tada;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }

  @keyframes wobble {
    from {
      transform: translate3d(0, 0, 0);
    }

    15% {
      transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
      transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
      transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .nav-block.wobble {
    animation-name: wobble;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }

  @keyframes heartBeat {
    0% {
      transform: scale(1);
    }

    14% {
      transform: scale(1.3);
    }

    28% {
      transform: scale(1);
    }

    42% {
      transform: scale(1.3);
    }

    70% {
      transform: scale(1);
    }
  }

  .nav-block.heartBeat {
    animation-name: heartBeat;
    animation-duration: 1.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  @keyframes jello {
    from,
    11.1%,
    to {
      transform: translate3d(0, 0, 0);
    }

    22.2% {
      transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
      transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
      transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
      transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
      transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
      transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
      transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
  }

  .nav-block.jello {
    animation-name: jello;
    transform-origin: center;
    animation-duration: 1s;
    animation-iteration-count: 1;
  }

  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }

    30% {
      transform: scale3d(1.25, 0.75, 1);
    }

    40% {
      transform: scale3d(0.75, 1.25, 1);
    }

    50% {
      transform: scale3d(1.15, 0.85, 1);
    }

    65% {
      transform: scale3d(0.95, 1.05, 1);
    }

    75% {
      transform: scale3d(1.05, 0.95, 1);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .nav-block.rubberBand {
    animation-name: rubberBand;
    animation-duration: 1s;
    animation-iteration-count: 1;
  }
`

const NavBackdrop = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`

const NavList = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 1.5625em 0 0 2.4375em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const NavListItem = styled.li`
  display: block;
  width: 3.3125em;
  height: 3.625em;
  margin-right: .9375em;
  margin-bottom: 2.0625em;
`

const NavListItemLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 20;
  transition: all 125ms ease-in-out;

  &:hover {
    transform: scale(1.06) translateY(-2px);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -25px;
    left: 50%;
    width: 74px;
    height: 25px;
    background: url(${roomBlockShadow}) no-repeat center;
    background-size: contain;
    transform: translateX(-50%);
    z-index: -1;
  }
`

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min
let animationTimeout

export default function StartPageContent({ lang, ...props }) {
  const intl = useIntl()
  const animations = ["bounce", "shake", "swing", "tada", "wobble", "heartBeat", "jello", "rubberBand"]
  const [animation, setAnimation] = useState({ block: 0, type: "" })
  const [store] = useStore()
  const componentIsMounted = useRef(true)

  const animate = () => {
    animationTimeout = setTimeout(() => {
      if (!componentIsMounted.current) {
        return
      }
      setAnimation({
        block: getRandomNumber(1, 8),
        type: animations[getRandomNumber(0, animations.length - 1)],
      })
    }, getRandomNumber(6000, 12000))
  }

  useEffect(() => {
    return () => {
      clearTimeout(animationTimeout)
      componentIsMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (store.animationsEnabled) {
      animate()
    } else {
      clearTimeout(animationTimeout)
    }
  }, [store.animationsEnabled])

  useEffect(() => {
    if (animation.block) {
      animationTimeout = setTimeout(() => {
        setAnimation({ block: 0, type: "" })
      }, 1500)
    } else {
      animate()
    }
  }, [animation])

  return (
    <Wrapper {...props}>
      <Nav aria-label={intl.formatMessage({ id: "mainMenu" })}>
        <Beam />
        <NavBackdrop alt="" src={navBackdrop} />
        <NavList>
          <NavListItem>
            <NavListItemLink to={getRoomLink(1, lang)} aria-label={intl.formatMessage({ id: "roomHeading1" })}>
              <img
                src={roomBlock1}
                alt=""
                className={`nav-block ${animation.block === 1 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(2, lang)} aria-label={intl.formatMessage({ id: "roomHeading2" })}>
              <img
                src={roomBlock2}
                alt=""
                className={`nav-block ${animation.block === 2 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(3, lang)} aria-label={intl.formatMessage({ id: "roomHeading3" })}>
              <img
                src={roomBlock3}
                alt=""
                className={`nav-block ${animation.block === 3 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(4, lang)} aria-label={intl.formatMessage({ id: "roomHeading4" })}>
              <img
                src={roomBlock4}
                alt=""
                className={`nav-block ${animation.block === 4 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(5, lang)} aria-label={intl.formatMessage({ id: "roomHeading5" })}>
              <img
                src={roomBlock5}
                alt=""
                className={`nav-block ${animation.block === 5 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(6, lang)} aria-label={intl.formatMessage({ id: "roomHeading6" })}>
              <img
                src={roomBlock6}
                alt=""
                className={`nav-block ${animation.block === 6 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(7, lang)} aria-label={intl.formatMessage({ id: "roomHeading7" })}>
              <img
                src={roomBlock7}
                alt=""
                className={`nav-block ${animation.block === 7 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink to={getRoomLink(8, lang)} aria-label={intl.formatMessage({ id: "roomHeading8" })}>
              <img
                src={roomBlock8}
                alt=""
                className={`nav-block ${animation.block === 8 ? animation.type : ""}`}
              />
            </NavListItemLink>
          </NavListItem>
        </NavList>
      </Nav>
    </Wrapper>
  )
}
